.selected-image {
  /* Centering the image within the modal */
  transform-origin: center center;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90vh;
  max-width: 95vw; /* max-width to 90% of the viewport width */
  width: auto;
  height: auto;
}
