.App {
  text-align: center;
}

.cursor-idle {
  cursor: url('/public/images/corsur/corsur-open.cur'), auto;
}
.cursor-clicked {
  cursor: url('/public/images/corsur/corsur-closed.cur'), auto;
}

.background {
  /* background-image: linear-gradient(45deg, #393e46, #000000, #212121); */
  height: 100vh; /* Full height of the viewport */
  width: 100%; /* Full width */
  z-index: -1;
}

.allrights {
  width: 100%;
  height: 3vh;
  color: white;
  font-weight: 700;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
