/* src/components/Gallery.css */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25vh, 1fr));
  grid-gap: 15px;
  overflow-y: auto;
}

.arrow {
  z-index: 999;
  /* background-color: black; */
  color: white;
  font-weight: 700;
  font-size: 2rem;
  padding: 1vh;
  width: 100%;
}

br {
  margin-bottom: 1vh;
}

.highlight:hover {
  background-color: rgba(0, 0, 0, 1); /* Choose any color */
}

.arrow.right {
  direction: ltr;
}
.arrow.left {
  direction: rtl;
}

.highlight {
  background-color: rgba(0, 0, 0, 0.6); /* Choose any color */
  padding: 2px; /* Optional, for better spacing around the text */
}

.gallery-item > * {
  width: 100%;
  aspect-ratio: 1/1.6;
  /* height: 100%; */
  object-fit: cover; /* This makes sure that images cover the area without stretching. */
  border-radius: 0.5vh; /* Optional: Adds rounded corners to your images */
}
.parent {
  direction: rtl;
  padding: 2vh;
}

.modal {
  position: fixed; /* Fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
}
.close-gallery {
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 0.5vh;
}

.show-gallery-button {
  direction: rtl;
  display: flex;
  box-sizing: border-box;
  height: 25vh;
  aspect-ratio: 1.6/1;
  color: black;
  border-radius: 0.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.x-button {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.6);
}
.x-button:hover {
  color: rgba(255, 255, 255, 1);
}

.details {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-family: 'Courier New', Courier, monospace;
  direction: rtl;
  position: fixed;
  padding: 1vh;
  top: 10%;
  left: 79%;
  /* transform: translate(50% 50%); */
}
